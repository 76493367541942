import { createSlice } from '@reduxjs/toolkit';
import {
  CompanySettingsIntegrationsDataResponse,
  CompanyAllUsersAsOptions,
} from '@/shared/types/controllers/CompanyControllerTypes';

export type IntegrationsPageState = {
  liveChatSettings: null | CompanySettingsIntegrationsDataResponse['app'];
  companyAgentsOptions: CompanyAllUsersAsOptions['options'];
  selectedCompanyAgents: CompanyAllUsersAsOptions['options'];
};

const getInitialStore = (): IntegrationsPageState => ({
  liveChatSettings: null,
  companyAgentsOptions: [],
  selectedCompanyAgents: [],
});

const integrationsPageSlice = createSlice({
  name: 'integrationsPage',
  initialState: getInitialStore(),
  reducers: {
    setLiveChatSettings: (store, { payload }) => {
      return {
        ...store,
        liveChatSettings: payload,
      };
    },
    setCompanyAgentsOptions: (store, { payload }) => ({
      ...store,
      companyAgentsOptions: payload,
    }),
    setSelectedCompanyAgents: (store, { payload }) => ({
      ...store,
      selectedCompanyAgents: payload,
    }),
  },
});

export const {
  setLiveChatSettings,
  setCompanyAgentsOptions,
  setSelectedCompanyAgents,
} = integrationsPageSlice.actions;

export default integrationsPageSlice.reducer;
