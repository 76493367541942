import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { disconnectSocket } from '@qb/httpRequest/SocketRequest';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { fetchFeatureFlips, setUser } from '@/src/store/reducers/main';
export const LOGOUT_EVENT_NAME = 'logout-event';
export const useLogoutRequest = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const router = useRouter();
  const {
    removeHasAuthenticatedLayoutCookie
  } = useHasAuthenticatedLayoutCookie();
  const tenantAlias = useTenantAlias();
  return useMutation({
    ...spaQueries.auth.logout(tenantAlias),
    onSuccess: () => {
      removeHasAuthenticatedLayoutCookie();
      dispatch(setUser(null));
      dispatch(fetchFeatureFlips({
        tenantAlias
      }));
      queryClient.removeQueries();
      disconnectSocket();
      localStorage.setItem(LOGOUT_EVENT_NAME, 'logout_' + Date.now());
      router.push('/login');
    }
  });
};