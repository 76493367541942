'use client';

import Image from 'next/image';
import Link from 'next/link';
import { Home, HelpOutline } from '@mui/icons-material/';
import { Box, Button, Stack, Typography } from '@mui/material';
import NotFoundImage from '@/public/static/ErrorPages/404.svg';
export const NotFoundPage = () => {
  return <Box sx={{
    display: 'flex',
    flexFlow: 'row wrap',
    minHeight: '90vh',
    height: '100%'
  }} data-sentry-element="Box" data-sentry-component="NotFoundPage" data-sentry-source-file="NotFoundPage.tsx">
      <Box sx={{
      px: 3,
      pt: 5,
      pb: 2,
      flex: '0 1 600px',
      height: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="NotFoundPage.tsx">
        <header>
          <Typography variant="h1" data-cy="NotFoundPageTitle" data-sentry-element="Typography" data-sentry-source-file="NotFoundPage.tsx">
            Page Not Found (404)
          </Typography>
          <Typography component="p" variant="h4" data-sentry-element="Typography" data-sentry-source-file="NotFoundPage.tsx">
            {`We can't seem to find the page you are looking for`}
          </Typography>
        </header>
        <Box mt={4} data-sentry-element="Box" data-sentry-source-file="NotFoundPage.tsx">
          <Typography component="p" variant="h4" data-sentry-element="Typography" data-sentry-source-file="NotFoundPage.tsx">
            Here are some helpful links instead:
          </Typography>
          <Stack direction="row" spacing={2} mt={2} data-sentry-element="Stack" data-sentry-source-file="NotFoundPage.tsx">
            <Link href="/" passHref legacyBehavior data-sentry-element="Link" data-sentry-source-file="NotFoundPage.tsx">
              <Button variant="outlined" color="secondary" startIcon={<Home />} data-cy="HomeButton" data-sentry-element="Button" data-sentry-source-file="NotFoundPage.tsx">
                Home
              </Button>
            </Link>
            <Link href="mailto:customer@quotebeam.com" passHref legacyBehavior data-sentry-element="Link" data-sentry-source-file="NotFoundPage.tsx">
              <Button variant="outlined" color="secondary" startIcon={<HelpOutline />} data-cy="HelpButton" data-sentry-element="Button" data-sentry-source-file="NotFoundPage.tsx">
                Help
              </Button>
            </Link>
          </Stack>
        </Box>
      </Box>
      <Box sx={{
      flex: '1 1 600px',
      minHeight: '300px',
      mb: 5,
      position: 'relative'
    }} data-sentry-element="Box" data-sentry-source-file="NotFoundPage.tsx">
        <Image src={NotFoundImage} alt="" fill style={{
        objectFit: 'contain'
      }} data-sentry-element="Image" data-sentry-source-file="NotFoundPage.tsx" />
      </Box>
    </Box>;
};