'use client';

import React from 'react';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { SPA_HOST } from '../../../SPAConstants';
import { siteMetadata } from './defaultSiteMetadata';
export const DefaultSeoConfig = () => {
  const router = useRouter();
  const canonicalURL = `${SPA_HOST}${(router.asPath === '/' ? '' : router.asPath).split('?')[0]}`;
  return <>
      <DefaultSeo title={undefined} titleTemplate={siteMetadata.title.template} defaultTitle={siteMetadata.title.default} description={siteMetadata.description} canonical={canonicalURL} dangerouslySetAllPagesToNoIndex={!siteMetadata.robots.index} dangerouslySetAllPagesToNoFollow={!siteMetadata.robots.follow} openGraph={siteMetadata.openGraph} additionalLinkTags={siteMetadata.icons.map(i => ({
      ...i,
      href: i.url
    }))} data-sentry-element="DefaultSeo" data-sentry-source-file="DefaultSEOConfig.tsx" />
    </>;
};