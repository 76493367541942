'use client';

import { ReactNode } from 'react';
import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Drawer, Stack, SxProps, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
type RightDrawerProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  drawerPaperSx?: SystemStyleObject;
  gridContainerSx?: SxProps;
};
export const RightDrawer = ({
  children,
  isOpen,
  onClose,
  onBack,
  drawerPaperSx = {},
  gridContainerSx = {}
}: RightDrawerProps) => {
  const backIcon = <IconButton aria-label="back" sx={{
    position: 'absolute',
    top: 16,
    left: 16
  }} onClick={onBack}>
      <ArrowBackIcon />
    </IconButton>;
  const closeIcon = <IconButton aria-label="close" onClick={onClose} size="small" sx={{
    position: 'absolute',
    p: 0.5,
    top: 32,
    right: 32,
    zIndex: 2
  }}>
      <Close />
    </IconButton>;
  return <Drawer transitionDuration={0} anchor="right" open={isOpen} onClose={onClose} PaperProps={{
    elevation: 0
  }} sx={{
    '& .MuiDrawer-paper': {
      width: [400, 600, 800, 'calc(100% - 228px)'],
      ...drawerPaperSx
    }
  }} data-sentry-element="Drawer" data-sentry-component="RightDrawer" data-sentry-source-file="RightDrawer.tsx">
      <Stack width="100%" height="100%" data-sentry-element="Stack" data-sentry-source-file="RightDrawer.tsx">
        {onBack && backIcon}
        {closeIcon}
        <Box sx={{
        overflowY: 'auto',
        maxHeight: '100%',
        height: '100%',
        ...gridContainerSx
      }} data-sentry-element="Box" data-sentry-source-file="RightDrawer.tsx">
          {children}
        </Box>
      </Stack>
    </Drawer>;
};