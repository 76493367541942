'use client';

import { memo, useState } from 'react';
import { usePathname } from 'next/navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Link, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Stack, SxProps, Typography } from '@mui/material';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
import { SidebarLink } from '@/src/components/Layouts/AppLayout/Sidebar/components/sidebarLinks';
import { SIZE_LARGE } from '../SidebarDesktopContainer';
type SidebarNavItemInternalProps = SidebarLink & {
  onClick?: () => void;
  isSidebarSmallSize?: boolean;
  listItemButtonSx?: SxProps;
};
const SidebarNavItemInternal = ({
  title,
  href,
  isBadgeVisible = false,
  Icon,
  subLinks,
  onClick,
  isSidebarSmallSize,
  listItemButtonSx
}: SidebarNavItemInternalProps) => {
  const pathname = usePathname();
  const isActive = pathname === href;
  const hasSubLinks = isNonEmptyArray(subLinks);
  const isSubLinkActive = subLinks?.some(({
    href
  }) => href === pathname);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(isSubLinkActive);
  const link = <ListItem disablePadding sx={{
    minWidth: SIZE_LARGE
  }}>
      <ListItemButton component={Link} href={href} onClick={onClick} sx={{
      py: 1,
      pl: isSidebarSmallSize ? 2.5 : 3,
      pr: 0,
      gap: isSidebarSmallSize ? 2.5 : 2,
      bgcolor: isActive ? 'primary.main' : 'transparent',
      '&:hover': {
        bgcolor: 'primary.dark'
      },
      ...listItemButtonSx
    }}>
        <ListItemIcon sx={{
        minWidth: 20
      }}>
          <Badge invisible={!isBadgeVisible} variant="dot" sx={{
          '.MuiBadge-badge': {
            bgcolor: 'warning.dark'
          }
        }}>
            <SvgIcon sx={{
            color: 'primary.contrastText',
            fontSize: 20
          }} component={Icon} />
          </Badge>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{
        variant: 'bodyMedium',
        color: 'primary.contrastText'
      }} primary={title} />
      </ListItemButton>
    </ListItem>;
  if (hasSubLinks) {
    return <Accordion disableGutters expanded={isAccordionExpanded} onChange={(_event, isExpanded) => {
      setIsAccordionExpanded(isExpanded);
    }} sx={{
      border: '0',
      backgroundColor: 'inherit',
      mb: 1
    }}>
        <AccordionSummary sx={{
        pl: isSidebarSmallSize ? 2.5 : 3,
        pr: isSidebarSmallSize ? 0 : 2,
        mb: 1,
        minHeight: 40,
        '&:hover': {
          bgcolor: 'primary.dark'
        },
        justifyContent: 'flex-start'
      }} expandIcon={<ExpandMoreIcon sx={{
        color: 'primary.contrastText',
        fontSize: isSidebarSmallSize ? 16 : 20
      }} />}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <SvgIcon sx={{
            color: 'primary.contrastText',
            fontSize: 20
          }} component={Icon} />
            {!isSidebarSmallSize && <Typography variant="bodyMedium" color="primary.contrastText">
                {title}
              </Typography>}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{
        p: 0
      }}>
          {subLinks.map((subLink, index) => <SidebarNavItemInternal key={index} {...subLink} listItemButtonSx={{
          gap: isSidebarSmallSize ? 2.5 : 2,
          pl: isSidebarSmallSize ? 2.5 : 4.5
        }} />)}
        </AccordionDetails>
      </Accordion>;
  }
  return link;
};
export const SidebarNavItem = memo(SidebarNavItemInternal);