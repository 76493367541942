import { socketErrorHandler } from './RequestPromise';
import { SocketGetRequestWithData, SocketPostRequest } from './SocketRequest';
import { getAppIdFromQueryString } from './utils';

/**
 * the appID is required for all socket requests to satisfy the policy `canViewConversationFromWidget`.
 */
const injectAppIdToSocketUrls = (url: string) => {
  const appId = getAppIdFromQueryString();
  if (appId) {
    const [uri, qs] = url.split('?');
    const params = new URLSearchParams(qs);
    params.set('appID', appId);
    return `${uri}?${params.toString()}`;
  }

  return url;
};

export const getSocketRequestPromise = async <Request, Response>(
  url: string,
  payload: Request | null = null,
  errorMessage?: string,
): Promise<Response> => {
  const urlWithAppID = injectAppIdToSocketUrls(url);
  return new Promise<Response>((resolve, reject) =>
    SocketGetRequestWithData(urlWithAppID, payload, resolve, reject),
  ).catch((e) => socketErrorHandler(e, errorMessage));
};

export const postSocketRequestPromise = async <Request, Response>(
  url: string,
  payload: Request | null = null,
  errorMessage?: string,
): Promise<Response> => {
  const urlWithAppID = injectAppIdToSocketUrls(url);
  return new Promise<Response>((resolve, reject) =>
    SocketPostRequest(urlWithAppID, payload, resolve, reject),
  ).catch((e) => socketErrorHandler(e, errorMessage));
};

export const appendParamsToUrl = (
  url: string,
  params: Record<string, string | undefined>,
) => {
  const [uri, paramString] = url.split('?');

  const urlSearchParams = new URLSearchParams(paramString);

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      urlSearchParams.set(key, value);
    }
  });

  return [uri, urlSearchParams.toString()].join('?');
};
