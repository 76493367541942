import { combineReducers } from 'redux';
import integrationsPage from '../components/Company/Settings/LiveChatSettings/store/reducer';
import conversationPage from '../components/Conversation/store/reducer';
import orderDisplayPage from '../components/Order/OrderDisplayPage/store/reducer';
import QuoteDisplayPageStore from './reducers/QuoteDisplayPageStore';
import main from './reducers/main';

const rootReducer = combineReducers({
  main,
  conversationPage,
  orderDisplayPage,
  QuoteDisplayPageStore,
  integrationsPage,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
