'use client';

import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { Provider } from 'react-redux';
import { makeStore, AppStore } from './store';
export const StoreProvider = ({
  children
}: PropsWithChildren) => {
  const storeRef = useRef<AppStore | null>(null);
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore();
  }
  return <Provider store={storeRef.current} data-sentry-element="Provider" data-sentry-component="StoreProvider" data-sentry-source-file="StoreProvider.tsx">{children}</Provider>;
};