'use client';

import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import CustomModalContext from '../../../contexts/CustomModalContext';
const CustomModal = dynamic(() => import('./CustomModal'));
export default function CustomModalWrapper(props) {
  const {
    activeComponent,
    isOpen,
    hideModal
  } = useContext(CustomModalContext);
  const theme = useTheme();
  if (!activeComponent?.component) {
    return null;
  }
  const noCloseButton = activeComponent.props.disableClose || activeComponent.props.hideCloseButton;
  const {
    component: Component
  } = activeComponent;
  return <CustomModal open={isOpen} onClose={!activeComponent.props.disableClose && hideModal} noCloseButton={noCloseButton} background={noCloseButton ? theme.palette.background.default : 'white'} maxWidth={activeComponent.props.maxWidth} data-sentry-element="CustomModal" data-sentry-component="CustomModalWrapper" data-sentry-source-file="CustomModalWrapper.jsx">
      {Component ? <Component router={props.router} {...activeComponent.props} /> : null}
    </CustomModal>;
}
CustomModalWrapper.propTypes = {
  router: PropTypes.object.isRequired,
  activeComponent: PropTypes.object
};