import { useParams } from 'next/navigation';
import { isServer } from '@qb/frontend/utils/env.utils';
import {
  parseHostToTenantAlias,
  TenantAlias,
} from '@/shared/tenants-alias-map';

// Careful, this hook will return undefined on server
export const useTenantAlias = (): TenantAlias => {
  const tenantAliasFromHostname = parseHostToTenantAlias(
    isServer ? '' : window.location.hostname,
    'agentHosts',
  );

  const params = useParams();

  // There's a known issue, when params.tenantAlias can have wrong value.
  // For example when ont /payment-terms, user types something into search query, next will make request with tenantAlias="payment-terms"
  // for some reason and that overrides the correct tenantAlias. Not sure what is causing this but, I suspect it's something in our middleware rewrite functions.
  // The workaround is to use tenantAliasFromHostname if it's defined with priority.
  return tenantAliasFromHostname || (params.tenantAlias as TenantAlias);
};
