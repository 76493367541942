import { getRequestPromise } from './RequestPromise';
import { axiosInstance } from './axiosInstance';

/**
 * @deprecated use `getRequestPromise` instead.
 */
export function GetRequest(url, successHandler, errorHandler, finallyHandler) {
  getRequestPromise(url)
    .then(successHandler)
    .catch(errorHandler)
    .finally(finallyHandler);
}

/**
 * @deprecated use `postRequestPromise` instead.
 */
export function PostRequest(
  url,
  data,
  successHandler,
  errorMessageOrHandler,
  finallyHandler,
) {
  const dataToUse = {};
  Object.keys(data || {}).forEach((key) => {
    if (data[key] === null || typeof data[key] === 'undefined') {
      dataToUse[key] = '';
    } else {
      dataToUse[key] = data[key];
    }
  });
  axiosInstance
    .post(url, dataToUse)
    .then((response) => {
      if (successHandler) {
        return successHandler(response.data, response);
      }
    })
    .catch((err) => {
      if (
        typeof errorMessageOrHandler === 'undefined' ||
        typeof errorMessageOrHandler === 'string'
      ) {
        // TODO: on user-initiated requests, then show an error, but not for background requests
        // let errorMessageToUse = errorMessageOrHandler || SERVER_ERROR_MESSAGE;
        // toast.error(errorMessageToUse);
      } else if (typeof errorMessageOrHandler === 'function') {
        errorMessageOrHandler(err?.response?.data, err.response);
      }
    })
    .then(() => {
      if (finallyHandler) {
        return finallyHandler();
      }
    });
}

/**
 * @deprecated use `postRequestPromise` instead.
 */
export function FileUploadRequest(
  url,
  params,
  file,
  successHandler,
  errorMessageOrHandler,
) {
  let data = new FormData();
  Object.keys(params || {}).forEach((key) => {
    data.append(key, params[key]);
  });
  // append file last so text is processed first
  // https://stackoverflow.com/questions/29764633/sails-js-form-post-not-submitting-any-data/31465544#31465544
  data.append('file', file);
  axiosInstance
    .post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (successHandler) {
        return successHandler(response.data, response);
      }
    })
    .catch((err) => {
      if (
        typeof errorMessageOrHandler === 'undefined' ||
        typeof errorMessageOrHandler === 'string'
      ) {
        // TODO: on user-initiated requests, then show an error, but not for background requests
        // let errorMessageToUse = errorMessageOrHandler || SERVER_ERROR_MESSAGE;
        // toast.error(errorMessageToUse);
      } else if (typeof errorMessageOrHandler === 'function') {
        errorMessageOrHandler(err?.response?.data, err.response);
      }
    });
}

/**
 * @deprecated use `deleteRequestPromise` instead.
 */
export function DeleteRequest(url, successHandler, errorMessageOrHandler) {
  axiosInstance
    .delete(url)
    .then((response) => {
      if (successHandler) {
        successHandler(response.data, response);
      }
    })
    .catch((err) => {
      if (
        typeof errorMessageOrHandler === 'undefined' ||
        typeof errorMessageOrHandler === 'string'
      ) {
        // TODO: on user-initiated requests, then show an error, but not for background requests
        // let errorMessageToUse =
        //   errorMessageOrHandler ||
        //   'Unable to delete item at this time. Please try again later.';
        // toast.error(errorMessageToUse);
      } else if (typeof errorMessageOrHandler === 'function') {
        errorMessageOrHandler(err?.response?.data, err.response);
      }
    });
}
