import { QueryClient } from '@tanstack/react-query';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      /** Don't retry queries on 404 */
      retry: (failureCount, error: unknown) => {
        // Don't retry on business logic errors.
        if (isApiBusinessLogicError(error)) {
          return false;
        }

        // default retry is 3 times only.
        return failureCount < 3;
      },
    },
  },
});
