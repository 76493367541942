import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { ENV_NAME } from '../../../../../SPAConstants';
export const useWarnOnContextWidthLargerThanViewport = () => {
  const pathname = usePathname();
  useEffect(() => {
    if (ENV_NAME === 'development') {
      const viewportWidth = document.documentElement.clientWidth;
      const contentWidth = window.innerWidth;
      // Warns the developer when the responsive design is overflowing outside the viewport and requires fixing.
      if (contentWidth > viewportWidth) {
        console.error('AppLayout: The content is larger than the viewport');
      }
    }
  }, [pathname]);
};