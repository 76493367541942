'use client';

import { useSelector } from 'react-redux';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { List } from '@mui/material';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { isAuthenticatedSelector } from '@/src/store/reducers/main';
import { SidebarNavItem } from './SidebarNavItem';
type Props = {
  onClick?: () => void;
};
export const SidebarAuthNav = ({
  onClick
}: Props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const {
    hideDrawer
  } = useRightDrawerContext();
  if (isAuthenticated) {
    return <List sx={{
      mt: 'auto'
    }}>
        <SidebarNavItem title="Logout" href="/logout" Icon={LogoutIcon} onClick={onClick} />
      </List>;
  }
  return <List sx={{
    mb: 'auto'
  }} onClick={() => hideDrawer()} data-sentry-element="List" data-sentry-component="SidebarAuthNav" data-sentry-source-file="SidebarAuthNav.tsx">
      <SidebarNavItem title="Login" href="/login" Icon={LoginIcon} onClick={onClick} data-sentry-element="SidebarNavItem" data-sentry-source-file="SidebarAuthNav.tsx" />
    </List>;
};