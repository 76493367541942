import { forwardRef } from 'react';
import { Inter } from 'next/font/google';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { colors, PaletteOptions } from '@mui/material';
import { createBaseThemeOptions } from '@qb/base-theme/base-theme';
import { addSharpCornersStyle, integrateThemeWithRouter, mergeCssVarsThemeOptions } from '@qb/base-theme/base-theme-utils';
import BlockedOnDemoLink from '@qb/frontend/components/BlockedOnDemoLink';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
const isBlockedURLInDemo = (href: string) => href === '/customers' || href === '/certificates' || href.startsWith('/settings') || href.startsWith('/inventory-and-pricing');
const BlockedOnDemoLinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  const hrefString = typeof props.href === 'string' ? props.href : props.href.pathname;
  return <BlockedOnDemoLink shouldShowBlockedTooltip={isBlockedURLInDemo(hrefString ?? '')} ref={ref} {...props} />;
});
const DefaultLinkBehaviour = forwardRef<HTMLAnchorElement, NextLinkProps>(function LinkBehaviour(props, ref) {
  return <NextLink ref={ref} {...props} />;
});
const inter = Inter({
  subsets: ['latin']
});
const fontFamily = `${inter.style.fontFamily},Helvetica Neue,Helvetica,Arial,sans-serif`;
const tenantPalette: Record<TenantAlias, Partial<PaletteOptions>> = {
  advantageelectricsupply: {
    primary: {
      main: '#2270E0',
      dark: '#103770',
      light: '#4C8BE6',
      contrastText: '#FFF'
    }
  },
  appliedautomation: {
    primary: {
      main: '#CB090D',
      dark: '#7A0104',
      light: '#F99494',
      '50p': '#D78887',
      '30p': '#E7B8B8',
      '12p': '#F4E2E2',
      '8p': '#F8ECEC',
      '4p': '#FCF5F6',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#1B1B1B',
      dark: '#1B1B1B',
      light: '#565656',
      '50p': '#8D8D8D',
      '30p': '#BBBBBB',
      '12p': '#E3E3E3',
      '8p': '#EDEDED',
      '4p': '#F6F6F6',
      contrastText: '#FFF'
    }
  },
  controlsforautomation: {
    primary: {
      '4p': '#FEFBF7',
      '8p': '#FEF6F0',
      '12p': '#FCF1E7',
      '30p': '#F8DEC4',
      '50p': '#F2C69C',
      dark: '#CA6000',
      main: '#F68D2E',
      light: '#F99494',
      contrastText: '#FFF'
    },
    secondary: {
      '4p': '#F5F5F5',
      '8p': '#EBEBEB',
      '12p': '#E0E0E0',
      '30p': '#B3B3B3',
      '50p': '#7F7F7F',
      dark: '#1B1B1B',
      main: '#000000',
      light: '#696969',
      contrastText: '#FFF'
    }
  },
  demo: {},
  mqautomation: {
    primary: {
      '4p': '#F6F9FB',
      '8p': '#ECF3F7',
      '12p': '#E2ECF2',
      '30p': '#B8D0E1',
      '50p': '#88AECC',
      dark: '#003456',
      main: '#0061A1',
      light: '#008CE8',
      contrastText: '#FFF'
    },
    secondary: {
      '4p': '#F5F5F5',
      '8p': '#EBEBEB',
      '12p': '#E0E0E0',
      '30p': '#B3B3B3',
      '50p': '#7F7F7F',
      dark: '#1B1B1B',
      main: '#000000',
      light: '#696969',
      contrastText: '#FFF'
    }
  },
  powermatic: {
    primary: {
      main: '#133D6D',
      dark: '#0F3157',
      light: '#1E61AE',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#00BB9A',
      dark: '#00BB9A',
      light: '#22FFD7',
      contrastText: colors.common.black
    }
  },
  quotebeam: {}
};
const getBaseTheme = (tenantAlias: TenantAlias) => createBaseThemeOptions(tenantAlias ? tenantPalette[tenantAlias] : {}, fontFamily);
export const spaThemeOptions = (tenantAlias: TenantAlias) => {
  const config = getConfigFromTenantAlias(tenantAlias);
  const isDemo = config?.isDemo;
  return mergeCssVarsThemeOptions(getBaseTheme(tenantAlias), integrateThemeWithRouter(isDemo ? BlockedOnDemoLinkBehaviour : DefaultLinkBehaviour), addSharpCornersStyle, {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          outlined: {
            backgroundColor: 'var(--mui-palette-common-white)'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--mui-palette-common-white)'
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--mui-palette-background-paper)'
          }
        }
      },
      MuiTableCell: {
        defaultProps: {},
        styleOverrides: {
          head: {
            color: 'var(--mui-palette-tertiary-contrastText)',
            backgroundColor: 'var(--mui-palette-tertiary-dark)',
            lineHeight: 1.5,
            textTransform: 'unset',
            '&:first-of-type': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            },
            '&:last-of-type': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }
          }
        }
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl'
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          invisible: {
            background: 'unset'
          },
          root: {
            background: 'rgba(141, 154, 171, 0.5)'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          colorAccent: {
            color: 'var(--mui-palette-text-primary)'
          }
        },
        defaultProps: {
          size: 'small'
        }
      }
    }
  });
};