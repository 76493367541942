'use client';

import React from 'react';
import { RightDrawer } from '@/src/components/Common/RightDrawer/RightDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
export const RightDrawerWrapper = () => {
  const {
    activeDrawer,
    isOpen,
    hideDrawer,
    drawerPaperSx,
    gridContainerSx
  } = useRightDrawerContext();
  if (!activeDrawer) {
    return null;
  }
  return <RightDrawer isOpen={isOpen} onClose={hideDrawer} drawerPaperSx={drawerPaperSx} gridContainerSx={gridContainerSx} data-sentry-element="RightDrawer" data-sentry-component="RightDrawerWrapper" data-sentry-source-file="RightDrawerWrapper.tsx">
      {activeDrawer}
    </RightDrawer>;
};