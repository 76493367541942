import type { IncomingMessage } from 'http';

// Should be used on server, where the req object is available (getServerSideProps, getInitialProps)
export const getHostFromReq = (req: IncomingMessage): string => {
  const host = req?.headers?.host;

  if (!host) {
    throw new Error('Could not get host from request');
  }

  return host;
};
