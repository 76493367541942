'use client';

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { recordEvent, setGA4CurrentUser, setGA4SalesPilotAppID, setPreviousRoute } from '@qb/frontend/analytics/ga4';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { getConfigFromTenantAlias } from '@/shared/tenants-alias-map';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { LOGOUT_EVENT_NAME } from '@/src/components/Auth/hooks/useLogoutRequest';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { setUser, fetchFeatureFlips } from '@/src/store/reducers/main';
export const AppTrackingAndAnalytics = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const tenantAlias = useTenantAlias();
  const config = getConfigFromTenantAlias(tenantAlias);
  setGA4SalesPilotAppID(config?.appID);
  const {
    isSuccess,
    data
  } = useGetCurrentUserInfoRequest();
  const logRouteToAnalytics = useCallback((url: string) => {
    spaQueries.analytics.logPageLoad(tenantAlias).mutationFn({
      url: url
    });
    recordEvent('view_page', {
      page: {
        info: {
          url: url
        }
      }
    });
  }, [tenantAlias]);
  useEffect(() => {
    dispatch(fetchFeatureFlips({
      tenantAlias
    }));
  }, [dispatch, tenantAlias]);
  useEffect(() => {
    const handleLogout = (event: StorageEvent) => {
      if (event.key === LOGOUT_EVENT_NAME) {
        dispatch(setUser(null));
        Sentry.setUser(null);
        setGA4CurrentUser(undefined);
        router.push('/login');
      }
    };
    window.addEventListener('storage', handleLogout);
    return () => {
      window.removeEventListener('storage', handleLogout);
    };
  }, [dispatch, router]);
  useEffect(() => {
    type RouteChangeHandler = (url: string, {
      shallow
    }: {
      shallow: boolean;
    }) => void;
    const handler: RouteChangeHandler = (url, {
      shallow
    }) => {
      if (shallow) {
        return;
      }
      setPreviousRoute(router.asPath);
      logRouteToAnalytics(url);
    };
    router.events.on('routeChangeStart', handler);
    return () => {
      router.events.off('routeChangeStart', handler);
    };
  }, [logRouteToAnalytics, router.asPath, router.events]);
  useEffect(() => {
    if (isSuccess) {
      setGA4CurrentUser(data.user);
      Sentry.setUser(data.user ? {
        id: String(data.user.id)
      } : null);
      logRouteToAnalytics(window.location.pathname);
    }
  }, [data?.user, isSuccess, logRouteToAnalytics]);
  return null;
};