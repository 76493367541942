import { SPA_HOST } from '../../../SPAConstants';

export const SITE_NAME = 'Sales Pilot Agent';

export const siteMetadata = {
  metadataBase: new URL(SPA_HOST || ''),
  title: {
    default: 'Sales Pilot Agent',
    template: `%s | ${SITE_NAME}`,
  },
  description: '',
  robots: {
    follow: false,
    index: false,
  },
  keywords: [],
  openGraph: {},
  icons: [
    {
      rel: 'apple-touch-icon',
      type: 'image/png',
      sizes: '180x180',
      url: '/favicons/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      url: '/favicons/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      url: '/favicons/favicon-32x32.png',
    },
    {
      rel: 'manifest',
      type: 'application/manifest+json',
      url: '/manifest.json',
    },
    {
      rel: 'manifest',
      type: 'application/manifest+json',
      url: '/site.webmanifest',
    },
  ],
};
