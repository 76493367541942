import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { Box, Container } from '@mui/material';
import { SidebarMobileContainer } from '@/src/components/Layouts/AppLayout/Sidebar/SidebarMobileContainer';
import { LinkedLogoAsSvg } from '../../../Logo/LinkedLogoAsSvg';
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from '../../navbarConstants';
const NavbarCallToActionButtons = dynamic(() => import('./NavbarCallToActionButtons').then(mod => mod.NavbarCallToActionButtons));
export const StickyNavbar = ({
  children
}: PropsWithChildren) => {
  return <Box component="header" sx={theme => ({
    background: `linear-gradient(90deg, ${theme.palette.common.black} 80%, ${theme.palette.primary.main})`,
    // Doesn't match anything from the theme
    boxShadow: '0 1px 4px 0 #15223214',
    position: 'sticky',
    top: 0,
    zIndex: 'drawer',
    height: {
      xs: NAVBAR_HEIGHT_MOBILE,
      md: NAVBAR_HEIGHT
    }
  })} data-sentry-element="Box" data-sentry-component="StickyNavbar" data-sentry-source-file="StickyNavbar.tsx">
      <Container maxWidth={false} sx={{
      px: [1, 1, 2],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: [1, 1, 4],
      height: '100%'
    }} data-sentry-element="Container" data-sentry-source-file="StickyNavbar.tsx">
        <SidebarMobileContainer data-sentry-element="SidebarMobileContainer" data-sentry-source-file="StickyNavbar.tsx" />
        <Box sx={{
        px: 1,
        maxHeight: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="StickyNavbar.tsx">
          <LinkedLogoAsSvg height={{
          xs: 50,
          md: 70
        }} variant="white" data-sentry-element="LinkedLogoAsSvg" data-sentry-source-file="StickyNavbar.tsx" />
        </Box>
        {children}
        <NavbarCallToActionButtons data-sentry-element="NavbarCallToActionButtons" data-sentry-source-file="StickyNavbar.tsx" />
      </Container>
    </Box>;
};