import { useCookie } from 'react-use';

export const NEXTJS_HAS_AUTHENTICATED_LAYOUT_COOKIE =
  'nextjs-has-authenticated-layout-cookie';

/**
 * ONLY USE IT FOR UI PURPOSES... DONT USE IT FOR APP-LOGIC
 */
export const useHasAuthenticatedLayoutCookie = () => {
  const [value, updateCookie, deleteCookie] = useCookie(
    NEXTJS_HAS_AUTHENTICATED_LAYOUT_COOKIE,
  );

  return {
    hasAuthenticatedLayoutCookie: !!value,
    setHasAuthenticatedLayoutCookie: () => updateCookie('true'),
    removeHasAuthenticatedLayoutCookie: () => deleteCookie(),
  };
};
