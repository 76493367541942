'use client';

import React, { useState } from 'react';

const CustomModalContext = React.createContext();

export const ModalContextProvider = (props) => {
  const [activeComponent, setActiveComponent] = useState({
    component: null,
  });
  const [isOpen, setIsOpen] = useState(false);

  const hideModal = () => {
    setIsOpen(false);
  };

  const setActiveView = (options) => {
    setActiveComponent({
      component: options.component,
      props: options.props || {},
    });
    setIsOpen(true);
  };

  return (
    <CustomModalContext.Provider
      value={{
        setActiveView: setActiveView,
        activeComponent: activeComponent,
        isOpen: isOpen,
        hideModal: hideModal,
      }}
    >
      {props.children}
    </CustomModalContext.Provider>
  );
};

export default CustomModalContext;
