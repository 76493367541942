// Polyfill for Chrome < 92 https://caniuse.com/mdn-javascript_builtins_array_at
import 'core-js/es/array/at';
// Polyfill for Chrome < 110 https://caniuse.com/mdn-javascript_builtins_array_toreversed
import 'core-js/es/array/to-reversed';
// Polyfill for Chrome < 110 https://caniuse.com/mdn-javascript_builtins_array_tosorted
import 'core-js/es/array/to-sorted';
// Polyfill for Chrome < 110 https://caniuse.com/mdn-javascript_builtins_array_tospliced
import 'core-js/es/array/to-spliced';
// Polyfill for Chrome < 71 https://caniuse.com/mdn-javascript_builtins_globalthis
import 'core-js/es/global-this';
// Polyfill for Chrome < 117 https://caniuse.com/mdn-javascript_builtins_map_groupby
import 'core-js/es/map/group-by';
// Polyfill for Chrome < 117 https://caniuse.com/mdn-javascript_builtins_object_groupby
import 'core-js/es/object/group-by';
// Polyfill for Safari < 15.4 https://caniuse.com/mdn-javascript_builtins_string_at
import 'core-js/es/string/at';

// Polyfill for Chrome < 103 https://caniuse.com/mdn-api_abortsignal_timeout_static
if (!AbortSignal.timeout) {
  AbortSignal.timeout = function timeout(ms) {
    const ctrl = new AbortController();
    setTimeout(() => ctrl.abort(), ms);
    return ctrl.signal;
  };
}

// Polyfill for Chrome < 92 https://caniuse.com/mdn-api_crypto_randomuuid
if (!crypto.randomUUID) {
  // https://stackoverflow.com/a/2117523/2800218
  crypto.randomUUID = () => {
    // @ts-expect-error - A numerical representation of ('10000000-1000-4000-8000-100000000000')
    const outputFormat = [1e7] + -1e3 + -1e3 + -1e3 + -1e11;
    return outputFormat.replace(/[01]/g, (c: number) => {
      const randomValue = crypto.getRandomValues(new Uint8Array(1))[0];
      return (c ^ (randomValue & (15 >> (c / 4)))).toString(16);
    });
  };
}
