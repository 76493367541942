import { createSlice } from '@reduxjs/toolkit';
import { ConversationDisplayDataResponse } from '@/shared/types/controllers/ConversationControllerTypes';

export type QuoteDisplayPageState = {
  conversation: ConversationDisplayDataResponse['conversation'] | null;
  hasFetchedConversation: boolean;
};

export const getInitialStore = (): QuoteDisplayPageState => ({
  conversation: null,
  hasFetchedConversation: false,
});

const QuoteDisplayPageStore = createSlice({
  name: 'QuoteDisplayPageStore',
  initialState: getInitialStore(),
  reducers: {
    setConversation: (store, { payload }) => {
      store.conversation = payload;
    },
    setHasFetchedConversation: (store, { payload }) => {
      store.hasFetchedConversation = payload;
    },
    addMessage: (store, { payload }) => {
      if (store.conversation) {
        store.conversation.messages = [...store.conversation.messages, payload];
      }
    },
    incrementNumUnreadMessages: (store) => {
      if (store.conversation) {
        store.conversation.numUnreadMessages =
          store.conversation.numUnreadMessages + 1;
      }
    },
    resetNumUnreadMessages: (store) => {
      if (store.conversation) {
        store.conversation.numUnreadMessages = 0;
      }
    },
  },
});

export const {
  setConversation,
  setHasFetchedConversation,
  addMessage,
  incrementNumUnreadMessages,
  resetNumUnreadMessages,
} = QuoteDisplayPageStore.actions;

export default QuoteDisplayPageStore.reducer;
