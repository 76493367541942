import { NotFoundPage } from './NotFoundPage';
import { ServerErrorPage } from './ServerErrorPage';
type Props = {
  statusCode?: number;
};
export const Error = ({
  statusCode
}: Props) => {
  if (statusCode && statusCode >= 400 && statusCode < 500) {
    return <NotFoundPage />;
  }
  return <ServerErrorPage data-sentry-element="ServerErrorPage" data-sentry-component="Error" data-sentry-source-file="Error.tsx" />;
};