import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { setUser } from '@/src/store/reducers/main';

export const useGetCurrentUserInfoRequest = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    setHasAuthenticatedLayoutCookie,
    removeHasAuthenticatedLayoutCookie,
  } = useHasAuthenticatedLayoutCookie();

  const tenantAlias = useTenantAlias();
  return useQuery({
    ...spaQueries.user.getCurrentUserInfo(tenantAlias),
    onSuccess: ({ user }) => {
      if (user?.company?.isSupplier !== true) {
        // logout if the SPA user is not a supplier
        return router.push('/logout');
      }

      if (user) {
        setHasAuthenticatedLayoutCookie();
      } else {
        removeHasAuthenticatedLayoutCookie();
      }
      dispatch(setUser(user));
    },
  });
};
