import { createSlice } from '@reduxjs/toolkit';

export type OrderDisplayPageState = {
  isOrderFilteredBySupplier: boolean;
  chosenOrderItemIDs: number[];
  // Map of OrderItemID to quantity
  quantityToBeShipped: Record<number, number>;
};

const getInitialStore = (): OrderDisplayPageState => ({
  isOrderFilteredBySupplier: true,
  chosenOrderItemIDs: [],
  // Map of OrderItemID to quantity
  quantityToBeShipped: {},
});

const orderDisplayPageSlice = createSlice({
  name: 'orderDisplayPage',
  initialState: getInitialStore(),
  reducers: {
    setIsOrderFilteredBySupplier: (store, { payload }) => ({
      ...store,
      isOrderFilteredBySupplier: payload,
    }),
    setchosenOrderItemIDs: (store, { payload }) => ({
      ...store,
      chosenOrderItemIDs: payload,
    }),
    updateQuantityToBeShipped: (store, { payload }) => {
      store.quantityToBeShipped[payload.orderItemID] = payload.quantity;
    },
  },
});

export const {
  setIsOrderFilteredBySupplier,
  setchosenOrderItemIDs,
  updateQuantityToBeShipped,
} = orderDisplayPageSlice.actions;

export default orderDisplayPageSlice.reducer;
